// load sass
import '../sass/common.scss'; //scssの読み込み
// modernizr // webp
import {modernizer}  from "./common/modernizr.js";
modernizer();
// load imageSwitch
import {imageSwitch} from "./common/sub_imageswtch.js";
imageSwitch();

// end scroll event
